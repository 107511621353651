<template>
  <b-overlay
    :show="submitting"
    spinner-variant="primary"
    spinner-type="grow"
    :variant="darkMode ? 'dark' : 'white'"
    spinner-small
    rounded="md"
  >
    <div class="auth-wrapper auth-v2">
      <b-row class="auth-inner m-0 justify-content-center">
        <!-- <lenon-auth-logo/> -->
        <div class="col-12 col-lg-6 col-xl-5 d-flex align-items-center auth-bg px-2 p-lg-5">
          <div class="col-12 col-sm-8 col-md-6 col-lg-12 col-xl-10 px-xl-2 mx-auto">
            <p class="text-center">
              <auth-logo />
            </p>
            <b-card-title
              title-tag="h2"
              class="font-weight-bold mb-1 text-center"
            >
              {{ $t('welcome_to') }} Lenon
            </b-card-title>
            <b-card-text class="mb-2 text-center">
              {{ $t('login_note') }}
            </b-card-text>

            <!-- form -->
            <validation-observer
              ref="loginValidation"
              v-slot="{ invalid }"
            >
              <b-form
                class="auth-login-form mt-2"
                @submit.prevent="login()"
              >
                <lenon-input
                  v-model="credentials.email"
                  name="email"
                  rules="required|email"
                  :show-label="false"
                  placeholder="Email"
                />

                <lenon-input
                  v-model="credentials.password"
                  name="password"
                  rules="required"
                  :type="passwordType ? 'password' : null"
                  :suffix-icon="passwordType ? 'LockIcon' : 'UnlockIcon'"
                  placeholder="············"
                  :show-label="false"
                  @onSuffixIconClick="() => { passwordType = !passwordType }"
                />
                <div class="row">
                  <div class="col-md-6">
                    <b-form-group>
                      <b-form-checkbox
                        id="remember-me"
                        v-model="credentials.remember_me"
                        name="checkbox-1"
                      >
                        {{ $t('remember_me') }}
                      </b-form-checkbox>
                    </b-form-group>
                  </div>
                  <div class="col-md-6">
                    <b-link @click="$router.push({ name: 'reset' })">
                      {{ $t('forgot_password') }}
                    </b-link>
                  </div>
                </div>
                <!-- submit buttons -->
                <div class="mt-1 d-flex justify-content-between align-items-start">
                  <lenon-button
                    variant="outline-warning"
                    label="register"
                    icon="UserPlusIcon"
                    @onClick="() => { $router.push({ name: 'register' }) }"
                  />
                  <lenon-button
                    name="login"
                    class="float-right"
                    :disabled="invalid"
                    label="login"
                    type="submit"
                  />
                </div>
              </b-form>
            </validation-observer>
          </div>
        </div>
        <!-- /Login-->
      </b-row>
    </div>
  </b-overlay>
</template>

<script>
/* eslint-disable global-require */
import { ValidationObserver } from 'vee-validate'
import {
  BRow,
  BLink,
  BFormGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BForm,
  BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import useAppConfig from '@core/app-config/useAppConfig'
import LenonInput from '@/lenon/components/LenonInput.vue'
import LenonButton from '@/lenon/components/LenonButton.vue'
import { LOGIN_M } from '@/graphql/mutations'
import showToast from '@/lenon/mixins/showToast'
import requiredData from '@/lenon/mixins/requiredData'
import AuthLogo from '@/lenon/components/AuthLogo.vue'

const { skin } = useAppConfig()

export default {
  components: {
    AuthLogo,
    LenonButton,
    LenonInput,
    BRow,
    BLink,
    BFormGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BForm,
    ValidationObserver,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  mixins: [showToast, requiredData],
  data() {
    return {
      submitting: false,
      passwordType: true,
      credentials: {
        email: '',
        password: '',
        remember_me: false,
      },
    }
  },
  computed: {
    darkMode() {
      return skin.value === 'dark'
    },
  },
  methods: {
    login() {
      this.submitting = true
      this.$apollo.mutate({
        mutation: LOGIN_M,
        variables: {
          ...this.credentials,
        },
      }).then(res => {
        this.$store.commit('auth/setUserData', res.data.login)
        this.$store.commit('auth/setRememberMe', this.credentials.remember_me)
        localStorage.setItem('role', res.data?.login?.role)
        localStorage.setItem('permissions', JSON.stringify(res.data?.login?.permissions))
        const permissions = this.$store.getters['auth/formattedPermissions']
        if (permissions) {
          this.$ability.update(permissions)
        }
        this.showSuccess('Login Successful, We are loading some additional data...')
        if (res.data.login.multiple_accounts) {
          this.showInfo('You are associated with multiple accounts.')
          this.$router.push({ name: 'active-accounts' })
        } else {
          this.fetchRequiredData().then(() => {
            this.showSuccess('Just a moment...')
            setTimeout(() => {
              this.submitting = false
              window.location = '/'
            }, 2000)
          }).finally(() => {
          })
        }
      }).catch(error => {
        this.submitting = false
        console.error(error)
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
