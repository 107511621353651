import { FETCH_REQUIRED_DATA_Q } from '@/graphql/queries'

export default {
  methods: {
    fetchRequiredData() {
      return new Promise((resolve, reject) => {
        this.$apollo.query({ query: FETCH_REQUIRED_DATA_Q })
          .then(res => {
            this.$store.dispatch('home/setRequiredData', res.data)
            resolve(true)
          })
          .catch(err => {
            // this.showError('Failed to load some required data')
            reject(err)
          })
      })
    },
  },
}
